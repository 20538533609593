import React from 'react';

const PasteArea = ({ onProcessData }) => {
  // Handle pasted data
  const handlePaste = (e) => {
    const pastedText = e.target.value.trim();
    if (!pastedText) return;
  
    // Split by newline for rows, then by tab for each cell in a row
    const sheet = pastedText
      .split('\n')
      .map(row => row.split('\t'))
      .filter(row => row.some(cell => cell.trim() !== '')); // Keep rows with at least one non-empty cell
  
    console.log(sheet);
    onProcessData(sheet); // Pass cleaned data to the parent component
    e.target.value = ''; // Clear the textarea input
  };
  

  return (
    <div className="paste-area">
      <h3>...ou cole as células.</h3>
      <textarea
        placeholder="Cole todas as células do Orçamento Sintético"
        rows="3"
        className="paste-input"
        onChange={handlePaste}
      />
    </div>
  );
};

export default PasteArea;
