export const filterItems = (filterText, baseData, materialsData, showingServices) => {
  // Trim leading and trailing spaces from filterText
  const trimmedFilterText = filterText.trim();

  // Return all data if the filter text is empty or only spaces
  if (!trimmedFilterText) {
    return showingServices ? baseData : materialsData;
  }

  // Normalize the filter text: convert to lowercase and remove diacritics
  const normalizedFilterText = trimmedFilterText
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics

  // Match quoted terms or words, if no match, return empty array to prevent null issues
  const terms = normalizedFilterText.match(/"([^"]+)"|(\S+)/g) || [];

  // Separate include and exclude terms (terms starting with "-")
  const includeTerms = terms.filter(term => !term.startsWith('-')).map(term => term.replace(/"/g, ''));
  const excludeTerms = terms.filter(term => term.startsWith('-')).map(term => term.slice(1));

  const filteredData = showingServices ? baseData : materialsData;

  // Filter the data based on include and exclude terms
  return filteredData.filter(item => {
    // Normalize and clean item text for comparison

    const itemText = showingServices
      ? [item["d"], item["service_code"]].join(" ").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '')
      : [item["name"], item["code"]].join(" ").toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, '');

    // Check if all include terms are present
    const includesAll = includeTerms.every(term => itemText.includes(term));

    // Check if none of the exclude terms are present
    const excludesAll = excludeTerms.every(term => !itemText.includes(term));

    // Return true if the item satisfies both include and exclude conditions
    return includesAll && excludesAll;
  });
};
