import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServicesTable from '../components/ServicesTable';
import MaterialsTable from '../components/MaterialsTable';
import { filterItems } from '../components/FilterData';
import { Adsense } from '@ctrl/react-adsense';
import SinapiReference from '../components/SinapiReference';
import './styles/sinapiViewer.css';

const API_URL =  process.env.REACT_APP_API_URL || 'http://localhost:5000/';

const SinapiViewer = () => {
  const [baseData, setBaseData] = useState([]);
  const [materialsData, setMaterialsData] = useState([]);
  const [expandedServices, setExpandedServices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 300;

  const [filterText, setFilterText] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showingServices, setShowingServices] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);

  // State for SinapiReference data
  const [referenceData, setReferenceData] = useState({
    state: 'TO',
    month: 9,
    year: 2024,
    desoneracao: 'true',
  });

  

  useEffect(() => {
    const filtered = filterItems(filterText, baseData, materialsData, showingServices);
    setCurrentPage(1);
    if (showingServices) {
      setFilteredServices(filtered);
    } else {
      setFilteredMaterials(filtered);
    }
  }, [filterText, baseData, materialsData, showingServices]);

  const [isWideScreen, setIsWideScreen] = useState(false);
  useEffect(() => {
    // Check window width on component load
    if (window.innerWidth >= 900) {
      setIsWideScreen(true);
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleSearch = async () => {
    const { state, month, year, desoneracao } = referenceData;
    if (!state || !month || !year) return;

    setIsLoading(true);
    setNoDataFound(false);

    // Create a unique cache key based on the search parameters
    const cacheKey = `@cc-V2-sinapiData-${state}-${month}-${year}-${desoneracao}`;
    const cachedData = localStorage.getItem(cacheKey);
    

    if (cachedData) {
      // Parse the cached data and update the state
      const { services, materials } = JSON.parse(cachedData);
      setBaseData(services);
      setMaterialsData(materials);
      setFilteredServices(services);
      setFilteredMaterials(materials);

      setIsLoading(false);
      
    } else {
      try {
        console.log('Fetching new data');
    
        // Fetch both materials and services in a single call
        const response = await axios.get(`${API_URL}api/sinapiViewerData?state=${state}&month=${month}&year=${year}&desoneracao=${desoneracao}`);
        const { services, stringList, materials } = response.data;
    
        console.log(response.data);
        if (materials.length === 0) {
            setNoDataFound(true);
            return;
        }
    
        // Prepare materials as a lookup by code for quick access
        const materialMap = new Map(materials.map(material => [material.code, material.price]));
        
    
        // Dictionary of services by code
        const serviceMap = new Map(services.map(service => [service.service_code, { ...service, custo_total: null }]));
        
        const processedServices = new Set(); // Tracks services that have been fully populated
    
        function calculateServiceTotal(service) {
            let custo_total = 0;
    
            for (const component of service.components) {
                const { c, m, t } = component;
                let price;
    
                if (!t) {
                    // "t" is false, component is from materials
                    price = materialMap.get(c);
                    
                } else {
                    // "t" is true, component is from another service
                    const dependency = serviceMap.get(c);
                    if (!dependency || dependency.custo_total === null) {
                        return null; // Dependency is not yet resolved
                    }
                    price = dependency.custo_total;
                }
    
                if (price === undefined) {
                    console.warn(`Price not found for component code ${c}`);
                    return null; // Cannot proceed without a valid price
                }
    
                custo_total += price * m;
            }
    
            return custo_total;
        }
    
        let remainingServices = Array.from(serviceMap.values());
        
    
        // Process in stages based on dependencies
        while (remainingServices.length > 0) {
            const unprocessedCount = remainingServices.length;
    
            remainingServices = remainingServices.filter(service => {
                // Skip if already processed
                if (processedServices.has(service.service_code)) return false;
    
                // Try to calculate `custo_total`
                const total = calculateServiceTotal(service);
                if (total !== null) {
                    service.custo_total = total;
                    processedServices.add(service.service_code);
                    return false; // Remove from remaining
                }
                return true; // Keep in remaining to try again
            });
    
            if (remainingServices.length === unprocessedCount) {
                throw new Error('Circular dependency detected or incomplete material/service data');
            }
        }
    
        // Transform populated services for final output
        
        const populatedServices = services.map(service => ({
            ...service,
            d: service.d.map(phrase =>
                typeof phrase === 'string' ? phrase : stringList[phrase]
            ).join(''), // Join if you want a single string, or leave out if you want an array
            custo_total: serviceMap.get(service.service_code).custo_total
        }));
    
        // Update state with the fetched data
        setBaseData(populatedServices);
        setFilteredServices(populatedServices);
        setMaterialsData(materials);
        setFilteredMaterials(materials);
    
        // Cache the response data in localStorage
        const cacheKey = `${state}_${month}_${year}_${desoneracao}`;
        //localStorage.setItem(cacheKey, JSON.stringify({ services: populatedServices, materials, stringList }));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReferenceChange = (updatedData) => {
    setReferenceData((prev) => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleClosePopup = () => {
    setNoDataFound(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil((showingServices ? filteredServices : filteredMaterials).length / itemsPerPage);
  const currentItems = (showingServices ? filteredServices : filteredMaterials).slice(indexOfFirstItem, indexOfLastItem);

  const toggleExpandService = (uniqueIndex) => {
    setExpandedServices((prev) => ({
      ...prev,
      [uniqueIndex]: !prev[uniqueIndex],
    }));
  };

  return (
    <div>
      <div className="fixed-header">
        <h1>Consulta SINAPI</h1>
        <div className="header-content">
        {!isWideScreen && (
              <Adsense
                style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
                client="ca-pub-2344773042588967"
                slot="1408413103"
                data-ad-format="rectangle, horizontal"
                data-full-width-responsive="true"
              />
            )}
          <div className="header-left">
            <SinapiReference
              state={referenceData.state}
              month={referenceData.month}
              year={referenceData.year}
              desoneracao={referenceData.desoneracao}
              setState={(value) => handleReferenceChange({ state: value })}
              setMonth={(value) => handleReferenceChange({ month: value })}
              setYear={(value) => handleReferenceChange({ year: value })}
              setDesoneracao={(value) => handleReferenceChange({ desoneracao: value })}
              handleSearch={handleSearch}
              searchButton={true}
            />
          </div>
          <div className="header-center">
            <div className="button-group">
              <button
                className={!showingServices ? 'active' : ''}
                onClick={() => setShowingServices(false)}
                disabled={ baseData.length === 0}
                style={{
                  backgroundColor: isLoading || baseData.length === 0 ? '#d3d3d3' : '',
                  color: baseData.length === 0 ? '#999' : '',
                  cursor: baseData.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                Insumos
              </button>
              <button
                className={showingServices ? 'active' : ''}
                onClick={() => setShowingServices(true)}
                disabled={ baseData.length === 0}
                style={{
                  backgroundColor: isLoading || baseData.length === 0 ? '#d3d3d3' : '',
                  color: baseData.length === 0 ? '#999' : '',
                  cursor: baseData.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                Composições
              </button>
            </div>
            <div className="center-input-container">
              <input
                type="text"
                value={filterText}
                placeholder="Escreva os termos para filtrar"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          {isWideScreen && ( 
            <div className="header-right">
              <Adsense
                style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
                client="ca-pub-2344773042588967"
                slot="1408413103"
                data-ad-format="rectangle, horizontal"
                data-full-width-responsive="true"
              />
          </div>
        )}
        </div>

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}

        </div>
        <div>Resultados encontrados: {showingServices ? filteredServices.length : filteredMaterials.length}</div>
        <div className='super-container'>
        {isLoading && (
            <div className="overlay">
              <div className="spinner"></div>
            </div>
          )}
        <div className="table-container">

          <div className="scrollable">
            {showingServices ? (
              <ServicesTable
                services={currentItems.map((service) => ({
                  ...service,
                  uniqueIndex: service.service_code,
                }))}
                expandedServices={expandedServices}
                toggleExpandService={toggleExpandService}
                baseData={baseData}
                materialsData={materialsData}
              />
            ) : (
              <MaterialsTable materials={currentItems} />
            )}
          </div>
        </div>

        </div>

        {noDataFound && (
          <div className="popup">
            <div className="popup-content">
              <p>Não foram encontrados dados para esta pesquisa.</p>
              <button onClick={handleClosePopup}>Fechar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinapiViewer;
