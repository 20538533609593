import React, { useState } from "react";
import "./styles/homePage.css"; // import the CSS styles

import { Adsense } from '@ctrl/react-adsense';

const servicesData = [
  {
    id: 1,
    name: "Consulta SINAPI",
    description: "Consulte rapidamente todas as composições e insumos.",
    link: "/consulta",
  },
  {
    id: 2,
    name: "Insumos SINAPI",
    description:
      "Gere uma lista de todos os insumos do seu orçamento com Curva ABC.",
    link: "/gerar-insumos",
  },
  
];

const HomePage = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredServices = servicesData.filter((service) =>
    service.name.toLowerCase().includes(searchQuery)
  );


  return (
    <div className="homepage-container">
      <div className="header">
        <Adsense
          style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '95vw', margin: 'auto' }}
          client="ca-pub-2344773042588967"
          slot="1408413103"
          data-ad-format="rectangle, horizontal"
          data-full-width-responsive="true"
        />
        <h1>Ferramentas SINAPI</h1>
        <p>
          Ferramentas para otimizar seus serviços.
        </p>
      </div>

      <div className="services-grid">
        {filteredServices.map((service) => (
          <div className="service-card" key={service.id}>
            <h3>{service.name}</h3>
            <p>{service.description}</p>
            <a href={service.link} className="service-link">
              Acessar
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePage;
