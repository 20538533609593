// FilterControls.js
import React from 'react';

const SinapiReference = ({ state, month, year, desoneracao, setState, setMonth, setYear, setDesoneracao, handleSearch, searchButton }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => 2024 + index);

  return (
    <div className="input-container">
      <h3>Referência SINAPI</h3>
      <select value={state} onChange={(e) => setState(e.target.value)} className="select-field">
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
      </select>

      <select value={month} onChange={(e) => setMonth(Number(e.target.value))} className="select-field">
        <option value={1}>Janeiro</option>
        <option value={2}>Fevereiro</option>
        <option value={3}>Março</option>
        <option value={4}>Abril</option>
        <option value={5}>Maio</option>
        <option value={6}>Junho</option>
        <option value={7}>Julho</option>
        <option value={8}>Agosto</option>
        <option value={9}>Setembro</option>
        <option value={10}>Outubro</option>
        <option value={11}>Novembro</option>
        <option value={12}>Dezembro</option>
      </select>

      <select value={year} onChange={(e) => setYear(Number(e.target.value))} className="select-field">
        {years.map((year) => (
          <option key={year} value={year}>{year}</option>
        ))}
      </select>

      <select value={desoneracao} onChange={(e) => setDesoneracao(e.target.value)} className="select-field">
        <option value={true}>Desonerado</option>
        <option value={false}>Não Desonerado</option>
      </select>

      {searchButton ? <button onClick={handleSearch} className="search-button">Pesquisar</button> : ''}
    </div>
  );
};

export default SinapiReference;
