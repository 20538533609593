import React, { useState } from 'react';
import * as XLSX from 'xlsx';

const FileUpload = ({ onProcessData }) => {
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workbook, setWorkbook] = useState(null); // Keep track of the workbook

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const wb = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });

        // Store the workbook for later use
        setWorkbook(wb);

        // Get sheet names and open the modal immediately
        const sheetNames = wb.SheetNames;
        setSheets(sheetNames);
        setSelectedSheet(sheetNames[0]); // Set default selected sheet
        setIsModalOpen(true); // Open the modal immediately
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleConfirmSelection = () => {
    if (!workbook || !selectedSheet) return; // Guard clause
  
    // Get the raw sheet data as an array of arrays (2D array)
    const selectedSheetData = XLSX.utils.sheet_to_json(workbook.Sheets[selectedSheet], { header: 1, defval: '' });

    // Pass the raw data to the parent component
    console.log(selectedSheetData)
    onProcessData(selectedSheetData); // Pass raw data without processing
    setIsModalOpen(false); // Close modal after confirmation
  };

  return (
    <div className="file-upload-container">
        <h3>Carregue a planilha...</h3>
        <input
            id="upload-button"
            type="file"
            accept=".xlsx, .xls, .csv, .xlsm"
            title="Select an Excel file (.xlsx or .xls)"
            className="file-upload-input"
            
            onChange={handleFileUpload}
        />
        
        {isModalOpen && (
            <div className="modal-overlay">
                <div className="file-upload-modal">
                    <h4>Seleciona a aba do Orçamento Sinético</h4>
                    <select value={selectedSheet} onChange={(e) => setSelectedSheet(e.target.value)}>
                        {sheets.map((sheet, index) => (
                            <option key={index} value={sheet}>{sheet}</option>
                        ))}
                    </select>
                    <button className="file-upload-button" onClick={handleConfirmSelection}>Confirmar</button>
                </div>
            </div>
        )}
    </div>
);

};

export default FileUpload;
