import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

function unparse(value) {
  return typeof value === 'string' ? value.replace('.', ',') : value;
}

const AggregateInsumos = ({ insumosData, servicesData, toggleInsumos, showServices }) => {
  const [sortColumn, setSortColumn] = useState(); // Default sort by 'code'
  const [sortOrder, setSortOrder] = useState(); // Default order ascending
  const [classifiedInsumos, setClassifiedInsumos] = useState([]);

  const [filters, setParetoFilter] = useState(['A','B','C']);
  const [paretoLimits, setParetoLimits] = useState({upper:80, lower:95});

  // Function to classify based on Pareto
  const classifyPareto = (data) => {
    const totalCost = data.reduce((acc, insumo) => acc + insumo.totalPrice, 0);
    const sortedData = [...data].sort((a, b) => b.totalPrice - a.totalPrice); // Sort by totalPrice
    
    let cumulativeCost = 0;
    return sortedData.map((insumo) => {
      cumulativeCost += insumo.totalPrice;
      // If the cumulative cost including this item is less than or equal to 80%
      if (cumulativeCost <= totalCost * (paretoLimits.upper/100)) {
        return { ...insumo, paretoClass: 'A' };
      } 
      // If the cumulative cost is less than or equal to 95%
      else if (cumulativeCost <= totalCost * (paretoLimits.lower/100)) {
        return { ...insumo, paretoClass: 'B' };
      } 
      // Everything else is class C
      else {
        return { ...insumo, paretoClass: 'C' };
      }
    });
  };

  const exportToExcel = () => {
    // Filtered data for the "Insumos Only" sheet
    const insumosOnlyData = classifiedInsumos.map(insumo => ({
      'Código': insumo.code,
      'Descrição': insumo.name,
      'Quantidade Total': unparse(insumo.quantity),
      'Medida': insumo.measure,
      'Preço Unitário': unparse(insumo.price),
      'Preço Total': unparse(insumo.totalPrice),
      'Classificação Pareto': insumo.paretoClass,
    }));
  
    // Log for debugging
    console.log("Insumos Only Data:", insumosOnlyData);
  
    // Data for the "Insumos and Related Services" sheet
    const insumosWithServicesData = classifiedInsumos.flatMap(insumo => 
      insumo.services.map(service => ({
        'Código': insumo.code,
        'Descrição': insumo.name,
        'Serviço Código': service.service?.Código || 'N/A',
        'Serviço Descrição': service.service?.Descrição || 'N/A',
        'Quantidade por Serviço': unparse(service.quantity),
        'Preço por Serviço': unparse((service.quantity * insumo.price).toFixed(2)), // Assuming price per service is based on insumo price
        'Classificação Pareto': insumo.paretoClass,
      }))
    );
  
    // Log for debugging
    console.log("Insumos with Services Data:", insumosWithServicesData);
  
    // Create the sheets
    const workbook = XLSX.utils.book_new();
    const insumosOnlyWorksheet = XLSX.utils.json_to_sheet(insumosOnlyData);
    const insumosWithServicesWorksheet = XLSX.utils.json_to_sheet(insumosWithServicesData);
  
    // Append sheets to workbook
    XLSX.utils.book_append_sheet(workbook, insumosOnlyWorksheet, 'Insumos');
    XLSX.utils.book_append_sheet(workbook, insumosWithServicesWorksheet, 'Insumos por Serviço');
  
    // Write the file
    XLSX.writeFile(workbook, 'insumos_data.xlsx');
  };
  

  // Aggregate and classify insumos
  useEffect(() => {
    const aggregationMap = {};

    // Aggregate data
    insumosData.forEach((insumo) => {
      const service = servicesData.find((service) => service.Código === insumo.service_code);
      const quantity = service ? service.Quantidade * insumo.multiplier : 0;

      if (!aggregationMap[insumo.code]) {
        aggregationMap[insumo.code] = {
          ...insumo,
          quantity,
          totalPrice: insumo.price * quantity,
          services: [{ service, quantity }],
        };
      } else {
        aggregationMap[insumo.code].quantity += quantity;
        aggregationMap[insumo.code].totalPrice += insumo.price * quantity;
        if (service) aggregationMap[insumo.code].services.push({ service, quantity });
      }
    });

    // Classify insumos
    const aggregatedArray = Object.values(aggregationMap);
    const classifiedArray = classifyPareto(aggregatedArray);
    setClassifiedInsumos(classifiedArray);
  }, [insumosData, servicesData, paretoLimits]); // Run effect when data changes

  const sortedAggregatedInsumos = [...classifiedInsumos].sort((a, b) => {
    const valueA = a[sortColumn];
    const valueB = b[sortColumn];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }
    return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const totalSum = sortedAggregatedInsumos.reduce((sum, insumo) => sum + insumo.totalPrice, 0);


  return (
    <div>
    <div className='table-settings'>
      <button onClick={exportToExcel} className="export-button">Exportar em Excel</button>
      <div className="pareto-settings">
        <div className="pareto-limit-inputs">
          <label className="pareto-label">
            Limite A:
            <input
              type="number"
              name="upper"
              value={paretoLimits.upper}
              onChange={(e) => {
                const { name, value } = e.target;
                setParetoLimits((prevLimits) => ({
                  ...prevLimits,
                  [name]: parseInt(value),
                }));
              }}
              className="pareto-input"
            />
          </label>
          <label className="pareto-label">
            Limite B:
            <input
              type="number"
              name="lower"
              value={paretoLimits.lower}
              onChange={(e) => {
                const { name, value } = e.target;
                setParetoLimits((prevLimits) => ({
                  ...prevLimits,
                  [name]: parseInt(value),
                }));
              }}
              className="pareto-input"
            />
          </label>
        </div>

        <div className="pareto-filters">
          {['A', 'B', 'C'].map((type) => (
            <label key={type} className="filter-item">
              <input
                type="checkbox"
                checked={filters.includes(type)}
                onChange={(e) =>
                  setParetoFilter((prevFilters) =>
                    e.target.checked
                      ? [...prevFilters, type]
                      : prevFilters.filter((filter) => filter !== type)
                  )
                }
                className="filter-checkbox"
              />
              <span className="filter-label">Classe {type}</span>
            </label>
          ))}
        </div>
      </div>

    </div>
   
    <table>

      <thead>
        <tr>
          <th style={{ width: '5%' }} onClick={() => handleSort('code')}>Código</th>
          <th style={{ width: '35%' }} onClick={() => handleSort('name')}>Descrição</th>
          <th style={{ width: '10%' }} onClick={() => handleSort('quantity')}>Quantidade Total</th>
          <th style={{ width: '10%' }} onClick={() => handleSort('measure')}>Medida</th>
          <th style={{ width: '10%' }} onClick={() => handleSort('price')}>Preço Unitário</th>
          <th style={{ width: '15%' }} onClick={() => handleSort('totalPrice')}>Preço Total</th>
          <th style={{ width: '5%' }} onClick={() => handleSort('percentage')}>%</th>
          <th style={{ width: '10%' }} onClick={() => handleSort('paretoClass')}>Classificação Pareto</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        
      {sortedAggregatedInsumos
          .filter(insumo => filters.includes(insumo.paretoClass)) // Check if paretoClass is in filters
          .map((insumo, index) => (
          <React.Fragment key={insumo.code}>
            <tr style={{ backgroundColor: 'lightblue' }}>
              <td>{insumo.code}</td>
              <td>{insumo.name}</td>
              <td>{unparse(insumo.quantity.toFixed(2))}</td>
              <td>{insumo.measure}</td>
              <td>R$ {unparse(insumo.price.toFixed(2))}</td>
              <td>R$ {unparse(insumo.totalPrice.toFixed(2))}</td>
              <td>{((insumo.totalPrice / totalSum)*100).toFixed(2)}%</td>
              <td>{insumo.paretoClass}</td>
              <td>
                <button onClick={() => toggleInsumos(index)}>
                  {showServices[index] ? "Esconder Serviços" : "Mostrar Serviços"}
                </button>
              </td>
            </tr>
            {showServices[index] && (
              <tr>
                <td colSpan="9">
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr>
                        <th>Código</th>
                        <th>Serviço</th>
                        <th>Quantidade por Serviço</th>
                        <th>Preço por Serviço</th>
                      </tr>
                    </thead>
                    <tbody>
                      {insumo.services
                        .sort((a, b) => b.quantity - a.quantity)
                        .map(({ service, quantity }, idx) => (
                          <tr key={idx} style={{ backgroundColor: 'yellow' }}>
                            <td>{service?.Código}</td>
                            <td>{service?.Descrição}</td>
                            <td>{unparse(quantity.toFixed(2))}</td>
                            <td>R$ {unparse((quantity * insumo.price).toFixed(2))}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
    </div>
  );
};

export default AggregateInsumos;
