import React, { useEffect, useState } from 'react';
import { Adsense } from '@ctrl/react-adsense';
import './styles/materialsViewer.css';

import AggregateInsumos from '../components/aggregateInsumos';
import GroupInsumos from '../components/groupInsumos';

function unparse(value) {
  return typeof value === 'string' ? value.replace('.', ',') : value;
}

const Insumos = () => {
  const [insumosData, setInsumosData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [filters, setFilters] = useState({ A: true, B: true, C: true });
  const [view, setView] = useState('list'); // State to toggle view
  const [showServices, setShowServices] = useState([]); // State to toggle view
  const [showInsumos, setShowInsumos] = useState([]); // State to toggle view


  useEffect(() => {
    const storedInsumos = JSON.parse(sessionStorage.getItem('insumosData')) || [];
    const storedServices = JSON.parse(sessionStorage.getItem('servicesData')) || [];

    if (storedInsumos.length > 0 && storedServices.length > 0) {
      const flattenedInsumos = storedInsumos.flatMap(insumo => insumo.insumos);
      setInsumosData(flattenedInsumos);
      setServicesData(storedServices);
      sessionStorage.removeItem('insumosData');
      sessionStorage.removeItem('servicesData');
    }
  }, []);

  const toggleGroups = (uniqueIndex) => {
    setShowInsumos((prev) => ({
      ...prev,
      [uniqueIndex]: !prev[uniqueIndex],
    }));
  };

  const toggleInsumos = (uniqueIndex) => {
    setShowServices((prev) => ({
      ...prev,
      [uniqueIndex]: !prev[uniqueIndex],
    }));
  };

  const handleFilterChange = (classType) => {
    setFilters(prev => ({ ...prev, [classType]: !prev[classType] }));
  };

// No functional changes, only layout and structure modifications

return (
  <div className="insumos-page">
    <h2 className="page-title">Insumos List</h2>

    {/* Top section with buttons, filter options, and view toggle */}
    <div className="top-section">
      <div className="left-section">
        <button onClick={() => setView(view === 'list' ? 'detailed' : 'list')} className="view-toggle-button">
          {view === 'list' ? 'Agrupar por Serviço' : 'Lista de Insumos'}
        </button>
      </div>
    </div>

    {/* Ads and main table display */}
    <div className="ads-sticky-left">
      <Adsense
        style={{ display: 'block', margin: 'auto' }}
        client="ca-pub-2344773042588967"
        slot="1408413103"
        data-ad-format="rectangle, vertical"
        data-full-width-responsive="true"
      />
    </div>

    <div className="ads-sticky-right">
      <Adsense
        style={{ display: 'block', margin: 'auto' }}
        client="ca-pub-2344773042588967"
        slot="1408413103"
        data-ad-format="rectangle, vertical"
        data-full-width-responsive="true"
      />
    </div>

    <Adsense
      style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
      client="ca-pub-2344773042588967"
      slot="1408413103"
      data-ad-format="rectangle, horizontal"
      data-full-width-responsive="true"
    />

    {insumosData.length === 0 ? (
      <p className="no-data-message">Nenhum insumo encontrado.</p>
    ) : view === 'list' ? (
      <table className="data-table">
        <AggregateInsumos 
          insumosData={insumosData} 
          servicesData={servicesData} 
          toggleInsumos={toggleInsumos} 
          showServices={showServices} 
        />     
      </table>
    ) : (
      <table className="data-table">
        <GroupInsumos 
          servicesData={servicesData} 
          insumosData={insumosData} 
          showInsumos={showInsumos} 
          toggleGroups={toggleGroups} 
        />
      </table>
    )}
  </div>
);

};

export default Insumos;
