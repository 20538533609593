import React from 'react';

const MaterialsTable = ({ materials }) => {
  console.log(materials)
  return (
    <table className="materials-table">
      <thead>
        <tr>
          <th>Código</th>
          <th>Descrição</th>
          <th>Unidade</th>
          <th>Preço Unitário (R$)</th>
        </tr>
      </thead>
      <tbody>
        {materials.map((material) => (
          <tr key={material.code}>
            <td>{material.code}</td>
            <td>{material.name}</td>
            <td>{material.measure}</td>
            <td>{material.price}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MaterialsTable;
