import React from 'react';

const ServicesTable = ({ services, expandedServices, toggleExpandService, baseData, materialsData }) => {
  console.log(services)
  return (
    <table className="composition">
      <thead>
        <tr>
          <th>Expand</th>
          <th>Código</th>
          <th>Descrição</th>
          <th>Unidade</th>
          <th>Preço Unitário</th>
          <th>Duração Unitária</th>
        </tr>
      </thead>
      <tbody>
        {services.map((service, index) => (
          <React.Fragment key={`${service.service_code}-${index}`}>
            <tr >
              <td onClick={() => toggleExpandService(`${service.service_code}-${index}`)} style={{ cursor: 'pointer', textAlign: 'center', fontSize: '20px' }}>
                {expandedServices[`${service.service_code}-${index}`] ? '−' : '+'}
              </td>
              <td>{service.service_code}</td>
              <td>{service.d}</td>
              <td>{service.unidade}</td>
              <td>{service.custo_total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td>{service.duration ? service.duration.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "NA"}</td>
            </tr>

            {expandedServices[`${service.service_code}-${index}`] && (
              <tr>
                <td colSpan="6">
                  <ComponentTable
                    components={service.components}
                    toggleExpandService={toggleExpandService}
                    expandedServices={expandedServices}
                    baseData={baseData}
                    materialsData={materialsData}
                    parentCoeficiente={1}
                    parentIndex={`${service.service_code}-${index}`}
                  />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

const ComponentTable = ({ components, toggleExpandService, expandedServices, baseData, materialsData, parentIndex, parentCoeficiente }) => {
  const getComponentDetails = (component) => {
    // Fetch details from baseData or materialsData
    const material = materialsData.find(m => m.code === component.c);
    const baseComponent = baseData.find(b => b.service_code === component.c);

    return {
      descricao: material?.name || baseComponent?.d || '',
      unidade: material?.measure || baseComponent?.unidade || '',
      preco_unitario: material ? material.price : baseComponent?.custo_total || 0
    };
  };

  return (
    <table className="component-table">
      <thead>
        <tr>
          <th>Mostrar</th>
          <th>Tipo</th>
          <th>Código</th>
          <th>Descrição</th>
          <th>Coeficiente</th>
          <th>Unidade</th>
          <th>Preço Unitário</th>
          <th>Preço Total</th>
        </tr>
      </thead>
      <tbody>
        {components?.map((component, idx) => {
          const { descricao, unidade, preco_unitario } = getComponentDetails(component);
          const coeficiente = component.m;
          const adjustedTotal = preco_unitario * coeficiente * parentCoeficiente;

          return (
            <React.Fragment key={`${parentIndex}-${component.c}-${idx}`}>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  {component.t && (
                    <span onClick={() => toggleExpandService(`${parentIndex}-${component.c}-${idx}`)} style={{ cursor: 'pointer', fontSize: '20px' }}>
                      {expandedServices[`${parentIndex}-${component.c}-${idx}`] ? '−' : '+'}
                    </span>
                  )}
                </td>
                <td>{component.t ? "Composição" : "Insumo"}</td>
                <td>{component.c}</td>
                <td>{descricao}</td>
                <td>{coeficiente}</td>
                <td>{unidade}</td>
                <td>{preco_unitario.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                <td>{adjustedTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              </tr>

              {component.t && expandedServices[`${parentIndex}-${component.c}-${idx}`] && (
                <tr>
                  <td colSpan="8">
                    {baseData && (
                      <ComponentTable
                        components={baseData.filter(item => item.service_code === component.c)[0]?.components || []}
                        toggleExpandService={toggleExpandService}
                        expandedServices={expandedServices}
                        baseData={baseData}
                        materialsData={materialsData}
                        parentIndex={`${parentIndex}-${component.c}-${idx}`}
                        parentCoeficiente={coeficiente * parentCoeficiente}
                      />
                    )}
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default ServicesTable;
