import React from 'react';
import { Link } from 'react-router-dom';

const HeaderMenu = () => {
  return (
    <header className="top-menu">
      <div className="logo">
        <Link to="/">Casa e Obra</Link>
      </div>
      <nav className="nav-links">
        <ul>
          <li>
            <Link to="/gerar-insumos">Insumos</Link>
          </li>
          <li>
            <Link to="/consulta">Consulta</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderMenu;
