import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/homePage'; // The new page component
import SinapiViewer from './pages/sinapiViewer';
import ImportAnalyzer from './pages/importAnalyzer'; // The new page component
import Materials from './pages/materialsViewer'; // Ensure to import Materials component

import HeaderMenu from './components/headerMenu'; // Import the header menu

import "./App.css"

const App = () => {
  return (
    <Router>
      <div className="app-container">
        {/* Separate the header */}
        <HeaderMenu /> {/* This will be shown above all pages */}

        {/* Main content */}
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/consulta" element={<SinapiViewer />} />
            <Route path="/gerar-insumos" element={<ImportAnalyzer />} />
            <Route path="/insumos" element={<Materials />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};


export default App;
