import React, { useState, useEffect } from 'react';

function unparse(value) {
  return typeof value === 'string' ? value.replace('.', ',') : value;
}

const GroupInsumos = ({ servicesData, insumosData, showInsumos, toggleGroups }) => {
  const [sortColumn, setSortColumn] = useState('totalPrice'); // Default sort by totalPrice
  const [sortOrder, setSortOrder] = useState('dsc'); // Default order ascending
  const [classifiedGroups, setClassifiedGroups] = useState([]);

  // Function to classify groups based on Pareto
  const classifyPareto = (data) => {
    const totalCost = data.reduce((acc, group) => acc + group.totalPrice, 0);
    const sortedData = [...data].sort((a, b) => b.totalPrice - a.totalPrice); // Sort by totalPrice
    
    let cumulativeCost = 0;
    return sortedData.map((group) => {
      cumulativeCost += group.totalPrice;
      // Classify based on cumulative cost
      if (cumulativeCost <= totalCost * 0.8) {
        return { ...group, paretoClass: 'A' };
      } else if (cumulativeCost <= totalCost * 0.95) {
        return { ...group, paretoClass: 'B' };
      } else {
        return { ...group, paretoClass: 'C' };
      }
    });
  };

  // Aggregate and classify groups
  useEffect(() => {
    const aggregationMap = {};

    // Aggregate data
    servicesData.forEach((service) => {
      const relatedInsumos = insumosData.filter(insumo => service.Código === insumo.service_code);
      const totalGroupPrice = relatedInsumos.reduce(
        (sum, insumo) => sum + (insumo.multiplier * service.Quantidade * insumo.price || 0),
        0
      );

      aggregationMap[service.Código] = {
        service,
        relatedInsumos,
        totalPrice: totalGroupPrice,
      };
    });

    // Classify groups
    const aggregatedArray = Object.values(aggregationMap);
    const classifiedArray = classifyPareto(aggregatedArray);
    setClassifiedGroups(classifiedArray);
  }, [servicesData, insumosData]); // Run effect when data changes

  // Sort groups based on selected column
  const sortedGroups = [...classifiedGroups].sort((a, b) => {
    const valueA = sortColumn.includes('.') ? a[sortColumn.split('.')[0]][sortColumn.split('.')[1]] : a[sortColumn];
    const valueB = sortColumn.includes('.') ? b[sortColumn.split('.')[0]][sortColumn.split('.')[1]] : b[sortColumn];

    // Handle sorting of string and numeric values
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
    }
    return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
  });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  return (
    <>
      {/* Header Row */}
      <tr style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
        <td style={{ width: '15%' }} onClick={() => handleSort('service.Código')}>Código</td>
        <td style={{ width: '35%' }} onClick={() => handleSort('service.Descrição')}>Descrição</td>
        <td style={{ width: '15%' }} onClick={() => handleSort('service.Quantidade')}>Quantidade</td>
        <td style={{ width: '15%' }} onClick={() => handleSort('totalPrice')}>Total Preço</td>
        <td style={{ width: '10%' }}>Curva ABC</td>
        <td style={{ width: '10%' }}>Ações</td>
      </tr>

      {/* Render groups */}
      {sortedGroups.map((group, index) => {
        const { service, relatedInsumos, totalPrice, paretoClass } = group;
        const isDisabled = relatedInsumos.length === 0;

        return (
          <React.Fragment key={index}>
            {/* Group Row */}
            <tr style={{ cursor: 'pointer', backgroundColor: 'yellow' }}>
              <td>{service.Código}</td>
              <td>{service.Descrição}</td>
              <td>{service.Quantidade}</td>
              <td>R$ {totalPrice.toFixed(2)}</td>
              <td>{paretoClass}</td>
              <td>
                <button
                  onClick={() => toggleGroups(index)}
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? '#ddd' : '#007bff',
                    color: isDisabled ? '#888' : '#fff',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '4px',
                  }}
                >
                  {showInsumos[index] ? "Esconder Insumos" : "Mostrar Insumos"}
                </button>
              </td>
            </tr>

            {/* Show related insumos if available and toggled */}
            {relatedInsumos.length > 0 && showInsumos[index] && (
              <tr>
                <td colSpan="6">
                  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                      <tr style={{ fontWeight: 'bold', backgroundColor: 'royalblue' }}>
                        <td style={{ width: '10%' }}>Código</td>
                        <td style={{ width: '45%' }}>Descrição</td>
                        <td style={{ width: '20%' }}>Quantidade</td>
                        <td style={{ width: '5%' }}>Unidade</td>
                        <td style={{ width: '10%' }}>Preço Unitário</td>
                        <td style={{ width: '10%' }}>Preço Total</td>
                      </tr>
                    </thead>
                    <tbody>
                    {relatedInsumos
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .sort((a, b) => {
                        const totalInsumoPriceA = (a.multiplier * service.Quantidade * a.price) || 0;
                        const totalInsumoPriceB = (b.multiplier * service.Quantidade * b.price) || 0;
                        return totalInsumoPriceB - totalInsumoPriceA; // Sort in descending order
                    })
                    .map((insumo, idx) => {
                        const totalInsumoPrice = (insumo.multiplier * service.Quantidade * insumo.price).toFixed(2);
                        return (
                        <tr key={idx} style={{ backgroundColor: '#f9f9f9' }}>
                            <td>{insumo.code}</td>
                            <td>{insumo.name}</td>
                            <td>{(insumo.multiplier * service.Quantidade) || 0}</td>
                            <td>{insumo.measure}</td>
                            <td>R$ {insumo.price}</td>
                            <td>R$ {totalInsumoPrice}</td>
                        </tr>
                        );
                    })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default GroupInsumos;
